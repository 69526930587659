import React from "react"
const Section = ({ id, isEven = false, sectionContentClassName, children }) => {
  return (
    <section
      id={id}
      className={`${
        isEven
          ? "bg-theme-section-dark shadow-theme-section-importance-down"
          : "bg-theme-section-light"
      } md:flex md:items-center lg:min-h-sh`}
    >
      <div
        className={`${sectionContentClassName} max-w-content mx-auto relative w-full`}
      >
        {children}
      </div>
    </section>
  )
}

export default Section
