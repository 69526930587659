import React, { useEffect, useRef } from "react"
import { Helmet } from "react-helmet"

const Seo = ({ title, description }) => {
  var url = useRef()
  useEffect(() => {
    url.current = window.location.href
  })
  return (
    <Helmet htmlAttributes={{ lang: "de" }}>
      <title>{title}</title>
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="https://www.avalum.de/apple-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="https://www.avalum.de/apple-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="https://www.avalum.de/apple-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="https://www.avalum.de/apple-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="https://www.avalum.de/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="https://www.avalum.de/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="https://www.avalum.de/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="https://www.avalum.de/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="https://www.avalum.de/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="https://www.avalum.de/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="https://www.avalum.de/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="https://www.avalum.de/favicon-96x96.png"
      />
      <link
        defer
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="https://www.avalum.de/favicon-16x16.png"
      />
      <link defer rel="manifest" href="https://www.avalum.de/manifest.json" />
      <meta name="msapplication-TileColor" content="#0d1627" />
      <meta
        name="msapplication-TileImage"
        content="https://www.avalum.de/ms-icon-144x144.png"
      />
      <meta name="theme-color" content="#0d1627" />
      <meta name="description" content={description} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta property="og:image" content="https://www.avalum.de/og.png" />
      <meta property="og:url" content={url.current} />
      <link
        rel="stylesheet"
        href="https://site-assets.fontawesome.com/releases/v6.1.1/css/all.css"
      />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  )
}

export default Seo
