import React from "react"
const SectionInfo = ({ children }) => {
  return (
    <div className="flex-1 pb-16 pt-8 px-4 md:flex md:items-center md:p-16 lg:p-32">
      <div className="grid gap-y-4 sm:gap-y-6 md:gap-y-8">{children}</div>
    </div>
  )
}

export default SectionInfo
