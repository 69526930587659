import React from "react"
const SectionGrid = ({ sx, children }) => {
  return (
    <div className={`flex-1 grid lg:flex lg:items-center ${sx ? sx : ""}`}>
      {children}
    </div>
  )
}

export default SectionGrid
